import React from "react";
import { Autoplay, Swiper as RealSwiper } from 'swiper';
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import CharterHallLogo from "@images/charter_hall_logo.png";
import DexusLogo from "@images/dexus_logo.png";
import GptLogo from "@images/gpt_logo.png";
import LendLeaseLogo from "@images/lendlease_logo.png";
import MirvacLogo from "@images/mirvac_logo.png";
import VicinityLogo from "@images/vicinity_logo.png";

const LogoCarousel = () => {
  RealSwiper.use([Autoplay]);

  const params = {
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false
    },
    pagination: {
      clickable: true
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50
      }
    }
  }

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-8 md:py-12 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-md font-semibold text-gray-500 tracking-wide pb-10">
          WAIVPAY is Trusted by Australia's Leading Shopping Centres, Brands and Retailers
        </p>
        <Swiper {...params}>
          <div className="mx-auto">
            <img className="mx-auto h-24" src={MirvacLogo} alt="Mirvac Logo" />
          </div>
          <div className="mx-auto">
            <img className="mx-auto h-24" src={LendLeaseLogo} alt="Lend Lease Logo" />
          </div>
          <div className="mx-auto">
            <img className="mx-auto h-24 mt-2" src={GptLogo} alt="GPT Logo" />
          </div>
          <div className="mx-auto">
            <img className="mx-auto h-28" src={VicinityLogo} alt="Vicinity Logo" />
          </div>
          <div className="mx-auto">
            <img className="mx-auto h-8 mt-10" src={DexusLogo} alt="Dexus Logo" />
          </div>
          <div className="mx-auto">
            <img className="mx-auto h-14 mt-6" src={CharterHallLogo} alt="CharterHall Logo" />
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default LogoCarousel;
